<template>
    <Dragons :dragons=dragons :elements=elements :is-ranked=true ></Dragons>
</template>

<script>
import Dragons from './Dragons.vue'
export default {
    name: 'Ranked',
    components: {
        Dragons
    },
    props: {
        dragons: Array,
        elements: Array,
    },
}
</script>
